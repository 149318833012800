// src/components/About.js

import {React} from "react";
import MailchimpScript from '../../frontend-scripts/MailchimpScript';
import { Stack, Box, Paper, Typography } from "@mui/material";

export default function About() {
  return (

        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          spacing={10}
          alignItems="center"
          sx={{ mx: 10 }}
        >
          <Paper
            elevation={15}
            square={false}
            sx={{
              backgroundColor: '#fff',
              borderRadius: 3,
              width: 1,
              p: 5,
            }}
          >
            <Typography
              sx={{ color: '#051d29', fontWeight: 'bold' }}
              variant="h5"
            >
              At Prophecy, investing is done{' '}
              <Typography
                sx={{ color: '#FF9900', fontWeight: 'bold' }}
                display="inline"
                variant="h5"
              >
                different
              </Typography>
            </Typography>

            <Typography
              sx={{
                color: '#051d29',
                fontWeight: 'medium',
                mt: 2,
              }}
              variant="subtitle1"
            >
              Instead of placing your money in a basket of various stocks and
              simply holding this portfolio in perpetuity, Prophecy allows you
              to select between a variety of algorithms depending on your risk
              class and tolerance. Each algorithm itself is not a portfolio but
              rather a methodology that specifies trades to be taken on a daily
              basis. This way, your money is never idle and is always working
              for you using the most up to date machine learning and
              mathematical techniques.
            </Typography>
          </Paper>
          <Box sx={{ width: 1 }}>
            <MailchimpScript />
          </Box>
        </Stack>

  );}