import React, { createContext, useContext, useState } from 'react';

const IndicatorContext = createContext();

export const useIndicatorContext = () => {
    const context = useContext(IndicatorContext);
    if (!context) {
        throw new Error('useIndicatorContext must be used within an IndicatorProvider');
    }
    return context;
};

export const IndicatorProvider = ({ children }) => {
    const [indicators, setIndicators] = useState([]);
    const [indicatorNames, setIndicatorNames] = useState(['Close', 'High', 'Low', 'Open']);
    const [tickers, setTickers] = useState([]);
    return (
        <IndicatorContext.Provider value={{ indicators, setIndicators, indicatorNames, setIndicatorNames, tickers, setTickers}}>
            {children}
        </IndicatorContext.Provider>
    );
};