import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { NavLink, Outlet } from "react-router-dom";
import { Typography, useTheme, useMediaQuery, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#276382',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function ResponsiveAppBar() {
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky">
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            {/* Logo NavLink */}
            <NavLink to="/">
              <Box
                component="img"
                sx={{
                  height: isSmallScreen ? 50 : 100,
                  py: isSmallScreen ? 1 : 2,
                  px: isSmallScreen ? 2 : 5,
                  display: 'flex',
                }}
                alt="Your logo."
                src={"./navbarlogo.png"}
              />
            </NavLink>

            {/* Home Button */}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button key={"home"} sx={{ my: 2, marginLeft: 5, marginRight: 2, display: 'block' }}>
                <NavLink to="/">
                  <Typography
                    variant={isSmallScreen ? "body1" : "h5"}
                    color="#fff"
                    fontWeight="bold"
                    sx={{ "&:hover": { color: "#FF9900" } }}
                  >
                    Home
                  </Typography>
                </NavLink>
              </Button>
            </Box>

            {/* Demo Button */}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button key={"demo"} sx={{ my: 2, marginLeft: 2, display: 'block' }}>
                <NavLink to="/demo">
                  <Typography
                    variant={isSmallScreen ? "body1" : "h5"}
                    color="#fff"
                    fontWeight="bold"
                    sx={{ "&:hover": { color: "#FF9900" } }}
                  >
                    Demo
                  </Typography>
                </NavLink>
              </Button>
            </Box>

            {/* Create Button
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button key={"create"} sx={{ my: 2, marginLeft: 2, display: 'block' }}>
                <NavLink to="/create">
                  <Typography
                    variant={isSmallScreen ? "body1" : "h5"}
                    color="#fff"
                    fontWeight="bold"
                    sx={{ "&:hover": { color: "#FF9900" } }}
                  >
                    Create
                  </Typography>
                </NavLink>
              </Button>
            </Box> */}
          </Toolbar>
        </Container>
        <Outlet />
      </AppBar>
    </ThemeProvider>
  );
}

export default ResponsiveAppBar;