// src/components/About.js

import {React} from "react";
import { Box, responsiveFontSizes, ThemeProvider, createTheme } from "@mui/material";
import About from "../components/landing-page-components/About";
import VideoUpdate from "../components/landing-page-components/VideoUpdate";
import Introductions from "../components/landing-page-components/Introductions";

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function Landing() {
  return (
    <Box
      sx={{
        mt: 10,
        pb: 10,
        width: 1,
      }}
    >
      <ThemeProvider theme={theme}>
        
        <VideoUpdate />
        <About />
        <Introductions />
      
      </ThemeProvider>
    </Box>
  );
}

// repeat the same basic structure for all 6 components