// import { Outlet, Link } from "react-router-dom";
import { Route, Routes } from "react-router";
// import Navbar from "../components/Navbar";

import App from "../App";
import Demo from "./Demo";
// import Create from "./Create";
import Advanced from "./Advanced";

export default function Root() {
  return (
    <>
      {/* <Navbar /> */}
      {/* <Outlet /> */}
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="demo" element={<Demo />} />
        {/* <Route path="create" element={<Create/>}/> */}
        <Route path="advanced" element={<Advanced/>}/>
      </Routes>
    </>
  );
}