import React from 'react';
import ResponsiveAppBar from "../components/navbar-components/NavBar";

export default function Advanced(){
    return (

        <main className="body-font">
            <div className="bg-prophecy_navy">
                <ResponsiveAppBar />
            </div>
        </main>
    );
}