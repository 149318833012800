import React, { useEffect } from 'react';

const loadScript = (src, onload, onerror) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.onload = onload;
  script.onerror = onerror;
  document.body.appendChild(script);
  return script;
};

const MailchimpScript = () => {
  useEffect(() => {
    const handleScriptLoad = () => {
      try {
        window.fnames = [];
        window.ftypes = [];
        window.fnames[0] = 'EMAIL';
        window.ftypes[0] = 'email';
        window.fnames[1] = 'MMERGE1';
        window.ftypes[1] = 'text';
        window.fnames[2] = 'MMERGE2';
        window.ftypes[2] = 'text';
        window.$mcj = window.jQuery.noConflict(true);
      } catch (error) {
        console.error("Error in handleScriptLoad:", error);
      }
    };

    const handleScriptError = (error) => {
      console.error("Script load error:", error);
    };
    
    const script = loadScript('//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js', handleScriptLoad, handleScriptError);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="mc_embed_shell">
      <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
      <style type="text/css">
        {`
          #mc_embed_signup {
            background: #051d29;
            font: 14px Helvetica, Arial, sans-serif;
            width: 1;
            height: 50%;
            margin-left: 10px;
            color: #fff;
            border: 5px solid #fff;
            border-radius: 30px;
          }

          #mc_embed_signup input {
            color: #051d29;
          }

          #mc_embed_signup #mc-embedded-subscribe {
            background-color: transparent;
            color: #fff;
            border: 2px solid #FF9900;
            border-radius: 5px;
            text-align: center;
            // padding: 5px;
            font-size: 16px;
            cursor: pointer;
          }
          #mc_embed_signup #mc-embedded-subscribe:hover {
            background-color: #fff;
            color: #051d29;
            border: 2px solid #fff;
          }
        `}
      </style>
      <div id="mc_embed_signup">
        <form
          action="https://gmail.us14.list-manage.com/subscribe/post?u=e94d0db3503f79ac2ae2e2878&amp;id=87d28c6791&amp;f_id=00c1c2e1f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <h2>Join the Waitlist!</h2>
            <div className="indicates-required">
              <span className="asterisk">*</span> indicates required
            </div>

            <div className="mc-field-group">
              <label htmlFor="mce-MMERGE1">
                First Name <span className="asterisk">*</span>
              </label>
              <input type="text" name="MMERGE1" className="required" id="mce-MMERGE1" required />
            </div>

            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
            </div>
            
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_e94d0db3503f79ac2ae2e2878_87d28c6791" tabIndex="-1" />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                <p style={{ margin: '0px auto' }}>
                  <a href="http://eepurl.com/iS6vr-" title="Mailchimp - email marketing made easy and fun">
                    <span style={{ display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px' }}>
                      <img
                        className="refferal_badge"
                        src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-light.svg"
                        alt="Intuit Mailchimp"
                        style={{ width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center' }}
                      />
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MailchimpScript;
