// src/components/About.js

import {React} from "react";

export default function Logo() {

    return (
        <section id="logo">
        <div className="container mx-auto flex px-10 pt-20 md:flex-row flex-col items-center justify-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                <img
                    className="object-cover object-center rounded"
                    alt="logo"
                    src="./headericon.png"
                />
            </div>
        </div>

        </section>
    );
}
// repeat the same basic structure for all 6 components