import { Box, Typography } from "@mui/material";

export default function VideoUpdate() {
    return (
        <Box
          sx={{
            mt: 10,
            pb: 10,
            width: 1,
          }}
        >
          <Typography 
            sx={{ color: '#fff', fontWeight: 'bold', textAlign: 'center', mb: 5 }}
            variant="h5">
              Checkout our new demo video!
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              width: 1,
            }}
          >
            <iframe 
              display="block"
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/WI8zIWpwFpI?si=ty0gvjaKwAKDNMar" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowFullScreen="True"
            />
          </Box>
        </Box>
    );}