import React from 'react';

import ResponsiveAppBar from "./components/navbar-components/NavBar.js";
import ScreenDetection from "./tools/screenDetection.js";
import { Alert, Box, Button } from "@mui/material";

import Landing from "./pages/Landing.js";
import Logo from "./components/mobile-components/Logo.js";
import EmailIcon from '@mui/icons-material/Email';

export default function App() {

  let app;

  if (ScreenDetection() === true) {
    app = 
    <div className="bg-prophecy_navy">
        <Box
        sx={{
          width:1,
          height:"100vh",
          display:"flex",
          flexDirection:"column"
        }}
        style={{backgroundColor:"#051d29"}}>
          <Alert severity="info" sx={{my:3, mx:1}}>This would look a lot better on a Desktop.<br></br><br></br>You can access the Free Version there!</Alert>
          <Logo />

          <Box textAlign='center'>
            <a href="mailto:prophecyfinancecanada@gmail.com">
              <Button startIcon={<EmailIcon />} variant="outlined" sx={{mt:15, width:250, height:50}} style={{borderColor:"#F7FAE5", color:"#F7FAE5"}}>Contact Us!</Button>
            </a>
          </Box>

          <Box textAlign='center'>
            <a href="http://eepurl.com/iS6q_2">
              <Button startIcon={<EmailIcon />} variant="contained" sx={{mt:5, width:250, height:50}} style={{backgroundColor:"#FF9900", color:"black"}}>Keep up to Date</Button>
            </a>
          </Box>
        </Box>
        <br></br>
    </div>
  }
  else {
    app =
    <div className="bg-prophecy_navy">
      <ResponsiveAppBar />
      <Landing />
    </div>
  }

  return (
    <main className="body-font">
      {app}
    </main>
  );
}